<template>
  <div>
    <div class="main-hero-wrap pb-0">
      <header>
        <div class="container">
          <div class="logo">
            <a href="#">
              <img src="../../assets/logoipsum.svg" alt="" />
            </a>
          </div>
          <nav>
            <ul>
              <li>
                <a href="#">Home</a>
              </li>
              <li>
                <div class="c-badge">Sale</div>
                <a href="#">Pricing</a>
              </li>
              <li>
                <a href="#">Support</a>
              </li>
              <li>
                <a href="#">About</a>
              </li>
              <li>
                <a href="#">Contact</a>
              </li>
            </ul>
          </nav>
          <div class="header-buttons">
            <a href="#" class="basket">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                fill="currentColor"
                class="bi bi-person"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"
                />
              </svg>
              User Panel
            </a>
            <button class="hamburger-btn">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.5 4.375H2.5C2.15833 4.375 1.875 4.09167 1.875 3.75C1.875 3.40833 2.15833 3.125 2.5 3.125H17.5C17.8417 3.125 18.125 3.40833 18.125 3.75C18.125 4.09167 17.8417 4.375 17.5 4.375Z"
                  fill="white"
                />
                <path
                  d="M17.4999 8.54175H9.60828C9.26661 8.54175 8.98328 8.25841 8.98328 7.91675C8.98328 7.57508 9.26661 7.29175 9.60828 7.29175H17.4999C17.8416 7.29175 18.1249 7.57508 18.1249 7.91675C18.1249 8.25841 17.8416 8.54175 17.4999 8.54175Z"
                  fill="white"
                />
                <path
                  d="M17.5 12.7083H2.5C2.15833 12.7083 1.875 12.4249 1.875 12.0833C1.875 11.7416 2.15833 11.4583 2.5 11.4583H17.5C17.8417 11.4583 18.125 11.7416 18.125 12.0833C18.125 12.4249 17.8417 12.7083 17.5 12.7083Z"
                  fill="white"
                />
                <path
                  d="M17.4999 16.875H9.60828C9.26661 16.875 8.98328 16.5917 8.98328 16.25C8.98328 15.9083 9.26661 15.625 9.60828 15.625H17.4999C17.8416 15.625 18.1249 15.9083 18.1249 16.25C18.1249 16.5917 17.8416 16.875 17.4999 16.875Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </div>
      </header>
    </div>

    <div class="container">
      <div class="login-form-box">
        <div class="box-head">
          <strong>Login to account</strong>
          <div class="dots"></div>
        </div>
        <div class="box-content">
          <div class="row">
            <div class="col-12">
              <div class="alert error" v-if="message">
                {{ message }}
              </div>
            </div>
            <div class="col-12">
              <div class="v-form-item">
                <span>Email*</span>
                <input
                  type="text"
                  v-model="formData.email"
                  placeholder="Email"
                  :class="{
                    'is-invalid': submitted && $v.formData.email.$error,
                  }"
                />
                <div
                  v-if="submitted && !$v.formData.email.required"
                  class="invalid-feedback"
                >
                  This field is required!
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="v-form-item">
                <span>Password*</span>
                <input
                  type="password"
                  v-model="formData.password"
                  placeholder="Password"
                  :class="{
                    'is-invalid': submitted && $v.formData.password.$error,
                  }"
                />
                <div
                  v-if="submitted && !$v.formData.password.required"
                  class="invalid-feedback"
                >
                  This field is required!
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="v-form-item">
                <span>2FA Code (if enabled)</span>
                <input
                  type="text"
                  v-model="formData.totp"
                  placeholder="2FA code"
                />
              </div>
            </div>
            <button class="main-btn" @click="handleSubmit()">
              <b-spinner
                variant="light"
                class="mr-2"
                v-if="isLoadingBtn"
                small
              ></b-spinner>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.5168 2.5083C11.8168 1.97497 10.9502 1.66663 10.0002 1.66663C7.70016 1.66663 5.8335 3.53329 5.8335 5.83329C5.8335 8.13329 7.70016 9.99996 10.0002 9.99996C12.3002 9.99996 14.1668 8.13329 14.1668 5.83329"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M17.1585 18.3333C17.1585 15.1083 13.9501 12.5 10.0001 12.5C6.05013 12.5 2.8418 15.1083 2.8418 18.3333"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Login
            </button>
          </div>
        </div>
      </div>
      <div class="login-form-bottom">
        You dont have an account? <router-link to="/register">Register.</router-link>
      </div>
    </div>

    <br />
    <br />
    <br />

    <div class="newsletter">
      <div class="container">
        <div class="nl-box">
          <div class="text">
            <h3>Try it out now!</h3>
            <p>Start Shipping!</p>
          </div>
          <div class="buttons">
            <a href="#"> Get Started </a>
          </div>
        </div>
      </div>
    </div>

    <footer>
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="footer-info">
              <img src="../../assets/logoipsum.svg" alt="" />
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
            </div>
          </div>
          <div class="col-md-9">
            <div class="row">
              <div class="col-md-4">
                <div class="footer-links">
                  <strong>Company</strong>
                  <a href="#">Blog</a>
                  <a href="#">Help Center</a>
                  <a href="#">Sales Tools Catalog</a>
                </div>
              </div>
              <div class="col-md-4">
                <div class="footer-links">
                  <strong>Support </strong>
                  <a href="#">Partners</a>
                  <a href="#">Policy</a>
                  <a href="#">Programs</a>
                </div>
              </div>
              <div class="col-md-4">
                <div class="footer-links">
                  <strong>Get in touch</strong>
                  <a href="#">Support</a>
                  <a href="#">About us</a>
                  <a href="#">FAQ</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <p>© 2023 All rights reserved.</p>
      </div>
    </footer>
  </div>
</template>

<script>
import { BAlert, BSpinner } from "bootstrap-vue";
import { mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import axios from "axios";
export default {
  components: {
    BAlert,
    BSpinner,
  },
  data() {
    return {
      message: "",
      passShow: true,
      showForm: true,
      two_fa_code: "",
      formData: {
        email: "",
        password: "",
        totp: null,
      },
      user_data: null,
      isLoadingBtn: false,
      is_verified: false,
      submitted: false,
    };
  },
  validations: {
    formData: {
      email: { required },
      password: { required },
    },
  },
  methods: {
    ...mapActions(["LogIn", "LogInTFA"]),

    async handleSubmit() {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.formData.$invalid) {
        return;
      } else {
        this.isLoadingBtn = true;
        await this.LogIn(this.formData)
          .then((res) => {
            setTimeout(() => {
              this.isLoadingBtn = false;
              this.$router.push("/dashboard");
            }, 400);
          })
          .catch((err) => {
            this.message = this.$store.state.auth.notification.message;
            this.isLoadingBtn = false;
          });
      }
      this.submitted = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.alert {
  border-radius: 5px;
  padding: 5px 12px;
  font-weight: 500;
  width: 100%;
  max-width: 514px;
  color: #fff;
  text-align: start;
  &.success {
    background: #58bd7d;
  }
  &.error {
    background: #ff6838;
  }
}

input.is-invalid {
  border-color: #ff0022 !important;
}
.invalid-feedback {
  text-align: start !important;
}

.auth-image {
  background-image: url(../../assets/img/abstract.jpg);
}
</style>
